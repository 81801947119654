<template>
   <div class="login">
      <div class="login-container text-center">
         <div class="login-container-top">
            <h1 class="welcome">¡BIENVENID@!</h1>
         </div>
         <div class="login-container-bottom">
            <form>
               <div class="form-item email">
                  <div class="input-container">
                     <input
                        type="text"
                        placeholder="Ingresa tu RUT o tu correo"
                        v-model.trim="email"
                        required
                        @keydown.space.prevent
                        @keypress=     "validLoginInput(email)"
                        @keyup.delete= "validLoginInput(email)"
                     />
                  </div>
               </div>
               <div class="form-item password">
                  <div class="input-container">
                     <input
                        type="password"
                        v-model="password"
                        placeholder="Contraseña"
                        @keypress.enter="logIn()"
                        required
                     />
                  </div>
               </div>
               <div
                  class="form-item err"
                  :class="error_pass_mail && 'active-err'"
               >
                  Correo o contraseña invalidas
               </div>
               <div class="form-item login-btn">
                  <a
                     class="btn"
                     @click="logIn()"
                     :class="activeLoginBtn() && 'active'"
                  >
                     Iniciar Sesión
                  </a>
               </div>
            </form>
            <div class="forgot-pass" @click="recoverPass()">
               ¿Has olvidado tu contraseña?
            </div>
         </div>
      </div>
      <div class="login-footer">
         <div class="made-with">
            Creado con
            <img :src="heart" alt="" class="heart" />
            por &nbsp; <strong>CARPETRES</strong>
         </div>
         <img :src="background_white" alt="" class="back" />
      </div>
      <!-- Modal para recuperar contraseña -->
      <div class="login-recover-pass" v-if="recover">
         <div class="login-recover-pass-container">
            <div class="login-recover-pass-container-head">
               <div class="title">¿Has olvidado tu contraseña?</div>
               <div class="close" @click="closeRecover()">
                  <font-awesome-icon icon="times"></font-awesome-icon>
               </div>
            </div>
            <div class="login-recover-pass-container-body">
               <div class="input-container" v-if="!sendedMail">
                  <input
                     id="mail"
                     type="text"
                     placeholder="Ingresa correo"
                     v-model="recover_pass"
                     @keypress.esc="closeRecover()"
                     required
                  />
                  <!-- icono correo valido -->
                  <span v-if="valid_mail">
                     <font-awesome-icon icon="check"></font-awesome-icon>
                  </span>
                  <span class="error" v-if="incorrect_mail">
                     <font-awesome-icon icon="times"></font-awesome-icon>
                  </span>
               </div>
               <div class="invalid" :class="incorrect_mail && 'active'">
                  Correo inválido o inexistente
               </div>
               <div class="message" v-if="sendedMail">
                  El correo de recuperación ha sido enviado
               </div>
               <button class="btn sended" v-if="sendedMail">Ok</button>
               <button
                  class="btn"
                  :class="valid_mail && 'active'"
                  v-if="!sendedMail"
                  @click="passwordRecovery()"
               >
                  Enviar
               </button>
            </div>
         </div>
      </div>
      <Spinner v-if="show_spinner" />
   </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { LOGIN_WITH_ADM, CHECK_TOKEN, RECOVER_PASS  } from "@/apollo/mutations";
import Spinner from "../components/Spinner.vue";

export default {
   components: { Spinner },
   data() {
      return {
         email: "",
         password: "",
         login_type: null,
         focused_user: false,
         focused_pass: false,
         focused_recover: false,
         recover_pass: "",
         recover: false,
         valid_mail: false,
         incorrect_mail: false,
         sendedMail: false,
         error_pass_mail: false,
         err_recovery: false,
         heart: require("@/assets/img/corazon.svg"),
         background_white: require("@/assets/img/background_white.svg"),
         show_spinner: false,
      };
   },
   watch: {
      email: function () {
         setTimeout(() => {
            this.validLoginInput(this.email);
         }, 2000);
      },
      recover_pass: function () {
         setTimeout(() => {
            this.validUserEmail(this.recover_pass, "recovery");
         }, 2000);
      },
   },
   
   computed: {
      ...mapState("sidebar", ["modules"]),
      ...mapGetters("sidebar", [ "userHasOnlyOneModule"]),

   },
   
   mounted() {
      this.validLoginInput(this.email);
   },
   methods: {
      ...mapActions("sidebar", ["getModules"]),

      recoverPass() {
         this.recover = true;
      },
      closeRecover() {
         this.recover = false;
         this.recover_pass = "";
      },
      logIn() {
         if (
            this.email.length > 5 &&
            this.password.length > 3 &&
            this.login_type !== null
         ) {
            this.show_spinner = true;
            this.error_pass_mail = false;
            var b64_pass = this.password;
            for (let index = 0; index < 3; index++) {
               b64_pass = btoa(b64_pass);
            }
            this.$apollo
               .mutate({
                  mutation: LOGIN_WITH_ADM,
                  variables: {
                     login: this.email,
                     password: b64_pass,
                     tipo_login: this.login_type,
                     plataforma: "2",
                  },
               })
               .then((res) => {
                  this.show_spinner = false;
                  var resp = res.data.usuarioLoginTokenAdmin;
                  if (resp != null) {
                     this.checkToken(resp.token, resp.uuid);
                  } else {
                     this.error_pass_mail = true;
                  }
               })
               .catch((err) => {
                  console.log("err", err);
                  this.show_spinner = false;
                  this.error_pass_mail = true;
               });
         } else {
            this.validLoginInput(this.email);
         }
      },

      async getUserModules(idEmpresa, idPrivilegio) {
         const data = {
            id_empresa: idEmpresa,
            id_privilegio: idPrivilegio
         };
         await this.getModules(data);
      },

      async checkToken(t, u) {
         try {
            const resp = (await this.$apollo.mutate({
               mutation: CHECK_TOKEN,
               variables: {
                  token: t,
                  uuid: u,
               },
            })).data.checkToken;
            this.$ls.set("user", resp);
            this.$ls.set("token", { token: t, uuid: u, time: new Date() });
            await this.getUserModules( resp.empresa[0].id_empresa, resp.privilegio[0].id_tipo );
            if (this.userHasOnlyOneModule) {
               this.redirectToLoneModule(this.modules);
            } else {
               this.$router.push({ name: "Inicio" });
            }
            
         } catch (err) {
            console.log("err", err);
         }
      },

      passwordRecovery() {
         if (this.valid_mail && this.recover_pass.length > 5) {
            this.incorrect_mail = false;
            this.$apollo
               .mutate({
                  mutation: RECOVER_PASS,
                  variables: {
                     correo: this.recover_pass,
                  },
               })
               .then((res) => {
                  var resp = res.data.recuperarPassword;
                  if (resp != null) {
                     this.sendedMail = true;
                  } else {
                     this.incorrect_mail = true;
                  }
               })
               .catch((err) => {
                  console.log(err.data);
                  this.incorrect_mail = true;
               });
         }
      },
      addHrs() {
         {
            var fecha = new Date();
            fecha.setHours(fecha.getHours() + 8);
            return fecha;
         }
      },
      validUserEmail(email, from) {
         var regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

         setTimeout(() => {
            if (String(email).toLowerCase().match(regex)) {
               if (from == "login") {
                  this.icon_input_login = "check";
                  this.incorrect_mail = false;
               }
               if (from == "recovery") {
                  this.valid_mail = true;
                  this.incorrect_mail = false;
               }
               return true;
            } else {
               if (from == "login") {
                  this.icon_input_login = "error";
                  this.incorrect_mail = false;
               }
               if (from == "recovery") {
                  this.incorrect_mail = true;
                  this.valid_mail = false;
               }
            }
         }, 1000);
      },
      validLoginInput(input) {
         if (input.length > 5 && input !== "") {
            if (input.includes("@")) { // isNaN(input)
               // si es true quiere decir que es un email
               this.login_type = "1"; // 1 para correo
               this.validUserEmail(input, "login");
            } else {
               // si es false quiere decir que es Rut
               this.login_type = "2"; // 2 para rut
               if (input.length >= 8) {
                  this.icon_input_login = "check";
               } else {
                  this.icon_input_login = "error";
               }
            }
         }
      },
      activeLoginBtn() {
         return this.email !== "" && this.password.length >= 4 ? true : false;
      },
   },
};
</script>
